import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import userPageStyles from '../userPage/UserPage.styles';
import { getGanttAccessRoles, saveGanttAccessRoles } from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import issueFormFieldStyles from '../../issueCreateEdit/components/IssueFormField.styles';
import DeleteIcon from '../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import clsx from 'clsx';
import ganttSettingsStyles from './GanttSettings.styles';

const GanttSettings = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'gantt_settings_page_title' });
  const commonClasses = userPageStyles();
  const fieldsClasses = ganttSettingsStyles();
  const navigate = useNavigate();
  const [ganttAccessRolesOptions, setGanttAccessRolesOptions] = useState([]);
  const [ganttAccessRoles, setGanttAccessRoles] = useState([]);
  const [initialGanttAccessRoles, setInitialGanttAccessRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveSettingsloading, setSaveSettingsloading] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    let ignore = false;
    async function getAccessRoles() {
      const { data: roles } = await getGanttAccessRoles();
      if (!ignore) {
        return roles;
      }
    }
    getAccessRoles()
      .then(res => {
        const checkedRoles = res.accessRoles.filter(item => item.isChecked);
        checkedRoles.length > 0 && setInitialGanttAccessRoles(checkedRoles);
        checkedRoles.length > 0 && setGanttAccessRoles(checkedRoles);
        setLoading(false);
        setGanttAccessRolesOptions(res.accessRoles);
      })
      .catch(error => handleIssueError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleIssueError]);

  const saveConfirmationSettings = useCallback(async () => {
    setSaveSettingsloading(true);
    try {
      const { data: settings } = await saveGanttAccessRoles(ganttAccessRoles.map(item => item.valueId.toString()));
      if (settings) {
        setInitialGanttAccessRoles(ganttAccessRoles);
        //setGanttAccessRoles(ganttAccessRoles);
        setSaveSettingsloading(false);
        setSettingsChanged(false);
      }
    } catch (error) {
      setSaveSettingsloading(false);
      handleIssueError(error.response);
    }
  }, [ganttAccessRoles, handleIssueError]);

  const handleCancel = useCallback(() => {
    setGanttAccessRoles(initialGanttAccessRoles);
    setSettingsChanged(false);
  }, [initialGanttAccessRoles]);

  const handleChange = useCallback(value => {
    setGanttAccessRoles(value);
    setSettingsChanged(true);
  }, []);
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page title={pageTitle} width="100%">
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                {`Настройки`}
              </Typography>
            </Grid>
            <Grid item>
              <Grid item>
                <Button
                  variant="defaultGreyPrimary"
                  disableRipple
                  onClick={saveConfirmationSettings}
                  disabled={!settingsChanged}
                >
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="save_text" />
                  </Typography>
                </Button>
                <Button onClick={handleCancel} variant="defaultGreyPrimary" disableRipple sx={{ marginLeft: '8px' }}>
                  <Typography variant="h4" fontWeight={700}>
                    <FormattedMessage id="cancel" />
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box
            className={commonClasses.blockWrapper}
            padding={'0 28px 20px 28px'}
            marginTop={'16px'}
            position={'relative'}
          >
            {saveSettingsloading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <Grid container>
              <Grid item width={'50%'}>
                <FormControl
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <InputLabel className={fieldsClasses.selectLabel} sx={{ marginTop: '16px', width: '100%!important' }}>
                    <Typography variant="h5" color={'#41424E'}>
                      {`${intl.formatMessage({
                        id: 'access_roles',
                      })}`}
                    </Typography>
                  </InputLabel>
                  <Autocomplete
                    multiple
                    onChange={(event, newValue) => handleChange(newValue)}
                    className={clsx(fieldsClasses.filterSelect, fieldsClasses.filterSelectMultiple)}
                    noOptionsText={intl.formatMessage({ id: 'not_found' })}
                    options={ganttAccessRolesOptions}
                    getOptionLabel={option => option.valueName || ''}
                    disableClearable
                    disablePortal
                    value={ganttAccessRoles}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={index}
                          deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                          className={fieldsClasses.chip}
                          variant="outlined"
                          label={option.valueName}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.valueId}>
                        <Checkbox
                          icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                          checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.valueName}
                      </li>
                    )}
                    renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                    PopperComponent={props => (
                      <Popper {...props} sx={{ maxWidth: '100%!important' }} placement="bottom-start"></Popper>
                    )}
                    popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Page>
      )}
    </>
  );
};
export default injectIntl(GanttSettings);
