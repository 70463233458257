import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  confirmationInitial,
  deleteIssue,
  deleteIssueBulk,
  getIssueData,
  getIssuePermissions,
} from '../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import Page from '../../../packages/common/shared-ui/src/components/Page';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import userPageStyles from '../administration/userPage/UserPage.styles';
import IssueWatchers from './components/IssueWatchers/IssueWatchers';
import IssueSubTasks from './components/IssueSubTasks/IssueSubTasks';
import IssueRelated from './components/IssueRelated/IssueRelated';
import issuePageStyles from './IssuePage.styles';
import IssueFiles from './components/IssueFiles/IssueFiles';
import dayjs from 'dayjs';
import IssueTabHistory from './components/IssueHistory/IssueHistory';
import IssueTabContractStages from './components/IssueContractStages/IssueContractStages';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CopyIcon from '../../../packages/common/shared-ui/src/icons/CopyIcon';
import EditIconBig from '../../../packages/common/shared-ui/src/icons/EditIconBig';
import DeleteIssueIconBig from '../../../packages/common/shared-ui/src/icons/DeleteIssueIconBig';
import { store } from '../app/redux';
import parse from 'html-react-parser';
import WarningModal from '../../../packages/common/shared-ui/src/components/WarningModal';
import ClearFormFieldIcon from '../../../packages/common/shared-ui/src/icons/ClearFormFieldIcon';
import issueFormFieldStyles from '../issueCreateEdit/components/IssueFormField.styles';
import IssueConfirmations from './components/IssueConfirmations/IssueConfirmations';

const IssuePage = ({ intl }) => {
  const IssueAction = { COPY: 'copy', DELETE: 'delete', EDIT: 'edit', ADD_TIMESHEET: 'add_timesheet' };
  const params = useParams();
  const navigate = useNavigate();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [pageTitle, setPageTitle] = useState('');
  const [issue, setIssue] = useState({});
  const [issueServices, setIssueServices] = useState({});
  const [permissions, setPermissions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blockConfirm, setBlockConfirm] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [descriptionField, setDescriptionField] = useState(null);
  const [finalDescriptionField, setFinalDescriptionField] = useState(null);
  const [files, setFiles] = useState([]);
  const [showDeleteIssueWarning, setShowDeleteIssueWarning] = useState(false);
  const [containerHeight, setContainerHeight] = useState('');
  const commonClasses = userPageStyles();
  const issueCommonStyles = issuePageStyles();
  const issueCreateEditClasses = issueFormFieldStyles();
  const state = store.getState();
  const elRefs = useRef([]);

  useEffect(() => {
    let ignore = false;
    const getIssue = getIssueData(params.id);
    const getPermissions = getIssuePermissions(params.id);
    function handleResults(results) {
      const errors = results.filter(result => result.status === 'rejected').map(result => result.reason);
      if (errors.length) {
        const error = errors.map(item => item.response);
        handleIssueError(error);
      }
      if (!ignore) {
        return results.map(result => result.status !== 'rejected' && result.value.data);
      }
    }
    Promise.allSettled([getIssue, getPermissions]).then(data => {
      const [issue, permission] = handleResults(data);
      if (issue) {
        setIssue(issue.issue);
        setIssueServices(issue.services);
        const formattedCustoms = issue.issue.customs.map(item => ({ ...item, custom: true }));
        setAllFields(issue.issue.standards.filter(item => item.fieldId !== 'description').concat(formattedCustoms));
        setPageTitle(`${issue.issue.subject} #${issue.issue.issueId}`);
      }
      setDescriptionField(issue.issue.standards.find(item => item.fieldId === 'description'));
      permission && setPermissions(permission);
      !issue.services.isConfirmIssue && setLoading(false);
    });
    return () => {
      ignore = true;
    };
  }, [handleIssueError, params.id]);

  useEffect(() => {
    if (descriptionField) {
      if (files.length > 0) {
        const imgs = descriptionField?.valueName.split('!');
        imgs.reduce((acc, string, index) => {
          let finalValue = string;
          const value = files.find(item => string.includes('.') && string === item.filename);
          if (value) {
            finalValue = `<img src=${value.attachmentUrl} alt=${value.filename} />`;
          }
          imgs[index] = finalValue;
          return acc;
        }, []);
        setFinalDescriptionField(imgs);
      } else {
        setFinalDescriptionField(descriptionField?.valueName);
      }
    }
  }, [descriptionField, files]);

  useEffect(() => {
    if (issueServices.isConfirmIssue) {
      const url = generateRedirectUrl({
        host: window.location.origin,
        path: `/issues/${issue.issueId}`,
        redmineToken,
        isWithAuth: false,
        skipRedirection: true,
      });
      window.open(url, '_self');
    }
  }, [issue.issueId, issueServices, redmineToken]);

  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error[0].status}`);
      }
    },
    [navigate],
  );
  useEffect(() => {
    if (allFields.length > 0) {
      const items = document.getElementsByClassName('pageLine');
      const allFieldsHeight = [...items].reduce((acc, rec) => {
        const style = getComputedStyle(rec);
        const height = rec.offsetHeight + Number(style.marginTop.replace('px', ''));
        return acc + height;
      }, 0);
      allFieldsHeight > 0 && setContainerHeight(`${(allFieldsHeight + 200) / 2}px`);
    }
  }, [allFields.length]);

  const handleDeleteIssue = useCallback(
    async (force = false) => {
      const issueLink = state.user.project ? `/issues/?project=${state.user.project.redmineId}` : `/issues`;
      let params = {};
      force && (params.force = true);
      try {
        const response = await deleteIssue(issue.issueId, params);
        if (response) {
          navigate(issueLink);
        }
      } catch (error) {
        error.response.status === 420 && setShowDeleteIssueWarning(true);
        console.error('ERROR WITH DELETE', error);
      }
    },
    [issue.issueId, navigate, state.user.project],
  );

  const handleIssueAction = useCallback(
    action => {
      let url = '';
      switch (action) {
        case IssueAction.EDIT:
          url = state.user.project
            ? `/issues/${params.id}/edit?project=${state.user.project.redmineId}`
            : `/issues/${params.id}/edit`;
          window.open(url, '_self');
          break;
        case IssueAction.COPY:
          url = state.user.project
            ? `/issues/${params.id}/copy?trackerId=${issue.trackerId}&project=${state.user.project.redmineId}&projectId=${issue.projectId}`
            : `/issues/${params.id}/copy?trackerId=${issue.trackerId}&projectId=${issue.projectId}`;
          window.open(url, '_blank');
          break;
        case IssueAction.ADD_TIMESHEET:
          window.open(`/spentTime/create?projectId=${issue.projectId}&issueId=${issue.issueId}`, '_blank');
          break;
        case IssueAction.DELETE:
          handleDeleteIssue();
          break;
      }
    },
    [
      IssueAction.ADD_TIMESHEET,
      IssueAction.COPY,
      IssueAction.DELETE,
      IssueAction.EDIT,
      handleDeleteIssue,
      issue.issueId,
      issue.projectId,
      issue.trackerId,
      params.id,
      state.user.project,
    ],
  );
  const handleModalAction = useCallback(
    async e => {
      switch (e.currentTarget.attributes.value.value) {
        case 'save':
          await handleDeleteIssue(true);
          break;
        case 'delete':
          setShowDeleteIssueWarning(false);
          break;
      }
    },
    [handleDeleteIssue],
  );
  const handleDownloadFile = useCallback(
    (id, name) => {
      let hostUrl = '';
      hostUrl = generateRedirectUrl({ host: window.location.origin, path: id, redmineToken });
      const link = document.createElement('a');
      link.href = hostUrl;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    [redmineToken],
  );
  const handleConfirmIssue = useCallback(
    async id => {
      setBlockConfirm(true);
      try {
        let rows = [];
        id && rows.push(id);
        const { data: response } = await confirmationInitial(rows);
        setBlockConfirm(false);
        if (response) {
          navigate(`/confirmations/${Number(response)}`);
        }
      } catch (error) {
        console.error('ERROR WITH POST CONFIRMATION INITIAL', error);
        setBlockConfirm(false);
      }
    },
    [navigate],
  );
  const handleReinitConfirmation = useCallback(
    id => {
      let issuesIds = [];
      id && issuesIds.push(Number(id));
      navigate(`/confirmation/reinit?issues=${issuesIds}`);
    },
    [navigate],
  );
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page title={pageTitle} width="100%" sx={{ marginBottom: '34px' }}>
          <WarningModal
            issue
            simple
            isWarningModalOpen={showDeleteIssueWarning}
            handleToggle={handleModalAction}
            closeWarningModal={() => setShowDeleteIssueWarning(false)}
            title={
              /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
              `Вы уверены, что хотите удалить выбранные задачи? Так же будут удалены подзадачи и таймшиты. Удалить?`
            }
          />
          <Grid container justifyContent={'space-between'} marginBottom={'20px'} alignItems={'center'}>
            <Grid item>
              <Typography padding={'6px 0'} variant="h1" color={'#082253'}>
                {`${issue.trackerName} #${issue.issueId}`}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems={'center'}>
                {permissions.canInitConfirmation && (
                  <Button
                    disabled={blockConfirm}
                    variant="defaultBlueSecondary"
                    disableRipple
                    onClick={() => handleConfirmIssue(issue.issueId)}
                  >
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="confirmation_document_button" />
                    </Typography>
                  </Button>
                )}
                {permissions.canReinitConfirmation && (
                  <Button
                    variant="defaultBlueSecondary"
                    disableRipple
                    onClick={() => handleReinitConfirmation(issue.issueId)}
                  >
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="confirmation_reinit_button" />
                    </Typography>
                  </Button>
                )}
                {permissions.canLogTime && (
                  <Button
                    variant="defaultGreyPrimary"
                    disableRipple
                    onClick={() => handleIssueAction(IssueAction.ADD_TIMESHEET)}
                  >
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="spent_time_add" />
                    </Typography>
                  </Button>
                )}
                {permissions.canCopy && (
                  <Tooltip
                    PopperProps={{ className: issueCreateEditClasses.tooltipForm }}
                    placement="bottom-start"
                    title={intl.formatMessage({ id: 'copy_text' })}
                  >
                    <IconButton
                      sx={{ padding: '4px', marginLeft: '10px' }}
                      onClick={() => handleIssueAction(IssueAction.COPY)}
                    >
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {permissions.canEdit && (
                  <Tooltip
                    PopperProps={{ className: issueCreateEditClasses.tooltipForm }}
                    placement="bottom-start"
                    title={intl.formatMessage({ id: 'edit_text' })}
                  >
                    <IconButton
                      sx={{ padding: '4px', marginLeft: '10px' }}
                      onClick={() => handleIssueAction(IssueAction.EDIT)}
                    >
                      <EditIconBig />
                    </IconButton>
                  </Tooltip>
                )}
                {permissions.canDelete && (
                  <Tooltip
                    PopperProps={{ className: issueCreateEditClasses.tooltipForm }}
                    placement="bottom-start"
                    title={intl.formatMessage({ id: 'delete_text' })}
                  >
                    <IconButton
                      sx={{ padding: '4px', marginLeft: '10px' }}
                      onClick={() => handleIssueAction(IssueAction.DELETE)}
                    >
                      <DeleteIssueIconBig />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Box className={commonClasses.blockWrapper} padding={'24px 16px'}>
            {issue.hierarchy.map((item, index) => {
              let issueLink = state.user.project
                ? `/issues/${item.issueId}?project=${state.user.project.redmineId}`
                : `/issues/${item.issueId}`;
              item.level === '0' && (issueLink = `/projects/${item?.trackerId}`);
              return (
                <Box
                  key={index}
                  marginLeft={item.level > 1 ? `${item.level * 8}px` : 0}
                  marginTop={item.level === '0' ? 0 : item.level === '1' ? '16px' : '8px'}
                >
                  <Typography fontWeight={600} variant="h3">
                    {issue.issueId.toString() === item.issueId ? (
                      <Box color={'#082253'}>{item.subject}</Box>
                    ) : (
                      <Box>
                        <Link
                          to={issueLink}
                          target={'_blank'}
                          className={issueCommonStyles.issueLink}
                          style={{ color: '#082253' }}
                        >
                          <Box component={'span'}>
                            {item.level === '0' ? `${item.trackerName}` : `${item.trackerName} #${item?.issueId}: `}
                          </Box>
                        </Link>
                        <Box color={'#656985'} component={'span'}>
                          {item.subject}
                        </Box>
                      </Box>
                    )}
                  </Typography>
                </Box>
              );
            })}
            <Box marginTop={'4px'}>
              <Typography variant="h4" color={'#212346'}>
                {`${intl.formatMessage({ id: 'issue_add' })} `}
                <Link
                  target={'_blank'}
                  className={issueCommonStyles.issueLink}
                  to={generateRedirectUrl({
                    host: window.location.origin,
                    path: `/users/${issue.authorId}`,
                    redmineToken,
                  })}
                >
                  {issue.authorName}
                </Link>
                {` ${dayjs(issue.created).format('DD.MM.YYYY H:mm')}`}
                {` ${intl.formatMessage({ id: 'issue_page_updated' })}`}
                {` ${dayjs(issue.updated).format('DD.MM.YYYY H:mm')}.`}
              </Typography>
            </Box>
            <Box
              marginTop={'32px'}
              sx={{ maxHeight: containerHeight }}
              className={issueCommonStyles.fieldsHolder}
              padding={'0 16px'}
              position={'relative'}
            >
              {allFields &&
                allFields.length > 0 &&
                allFields.map((control, index) => (
                  <Grid
                    key={index}
                    ref={ref => {
                      elRefs.current[index] = ref;
                    }}
                    container
                    marginTop={'4px'}
                    sx={{ maxWidth: '50%' }}
                    className={'pageLine'}
                  >
                    <Grid item width={'200px'}>
                      <Typography variant="h4" color={'#41424E'} fontWeight={700}>
                        {control.custom
                          ? `${control.fieldName}:`
                          : `${intl.formatMessage({
                              id: `issue_page_${control.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                            })}:`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">
                        {control.fieldFormat === 'user' ? (
                          <Link
                            color={'#212346'}
                            target={'_blank'}
                            className={issueCommonStyles.issueLink}
                            to={generateRedirectUrl({
                              host: window.location.origin,
                              path: `/users/${control.valueId}`,
                              redmineToken,
                            })}
                          >
                            {control.valueName}
                          </Link>
                        ) : control.fieldFormat === 'issue_timesheets' ? (
                          <Link
                            target={'_blank'}
                            className={issueCommonStyles.issueLink}
                            to={`/spentTime?filters=timesheet.issue;eq;${control.valueId.join('||')}&groups=`}
                          >
                            {control.valueName}
                          </Link>
                        ) : control.fieldFormat === 'attachment' ? (
                          <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleDownloadFile(control.valueId, control.valueName)}
                            component={'span'}
                            className={issueCommonStyles.issueLink}
                          >
                            {control.valueName}
                          </Box>
                        ) : control.fieldFormat === 'link' ? (
                          <Link target={'_blank'} className={issueCommonStyles.issueLink} to={control.valueName}>
                            <Box sx={{ wordBreak: 'break-word' }}>{control.valueName}</Box>
                          </Link>
                        ) : control.fieldFormat === 'text' ? (
                          <Box
                            color={'#212346'}
                            component={'span'}
                            sx={{ wordBreak: 'break-word' }}
                            className={issueCommonStyles.pageText}
                          >
                            {parse(control.valueName)}
                          </Box>
                        ) : (
                          <Box color={'#212346'} component={'span'} sx={{ wordBreak: 'break-word' }}>
                            {control.valueName}
                          </Box>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Box>
            {descriptionField && finalDescriptionField && (
              <Box marginTop={'32px'} padding={'0 16px'}>
                <Grid container flexDirection={'column'}>
                  <Grid item width={'200px'}>
                    <Typography variant="h4" color={'#41424E'} fontWeight={700}>
                      {`${intl.formatMessage({
                        id: `issue_page_${descriptionField.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                      })}:`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    marginTop={'10px'}
                    //whiteSpace={'pre-line'}
                    className={issueCommonStyles.editorField}
                  >
                    {Array.isArray(finalDescriptionField)
                      ? finalDescriptionField.map((string, index) => {
                          return (
                            <Box key={index} component={'span'}>
                              {parse(string)}
                            </Box>
                          );
                        })
                      : parse(finalDescriptionField)}
                  </Grid>
                </Grid>
              </Box>
            )}
            <Grid container direction={'column'} justifyContent={'flex-start'} padding={'0 16px'}>
              {permissions.canViewWatchers && (
                <Grid item margin={'32px 0 0'}>
                  <IssueWatchers permissions={permissions}></IssueWatchers>
                </Grid>
              )}
              <Grid item margin={'32px 0 0'}>
                <IssueSubTasks
                  projectId={issue.projectId}
                  trackerId={issue.trackerId}
                  permissions={permissions}
                ></IssueSubTasks>
              </Grid>
              <Grid item margin={'32px 0 0'}>
                <IssueRelated permissions={permissions}></IssueRelated>
              </Grid>
              {permissions.canViewConfirmation && (
                <Grid item margin={'48px 0 0'}>
                  <IssueConfirmations></IssueConfirmations>
                </Grid>
              )}
              <Grid item margin={'32px 0 0'}>
                <IssueFiles permissions={permissions} obtainFiles={data => setFiles(data)}></IssueFiles>
              </Grid>
              {issueServices.hasContractStages && permissions.canManageContractStages && (
                <Grid item margin={'32px 0 0'} width={'100%'}>
                  <IssueTabContractStages></IssueTabContractStages>
                </Grid>
              )}
              <Grid item margin={'32px 0 0'}>
                <IssueTabHistory></IssueTabHistory>
              </Grid>
            </Grid>
          </Box>
        </Page>
      )}
    </>
  );
};
export default injectIntl(IssuePage);
