import { LOCALES } from './locales';

/* eslint-disable */
export default {
  [LOCALES.RUSSIAN]: {
    // GLOBAL
    me: 'Мне',
    save_text: 'Сохранить',
    save_text_and_go: 'Сохранить и продолжить',
    add_text: 'Добавить',
    add_user: 'Добавить участника',
    add_user_role: 'Добавить роль',
    apply_text: 'Применить',
    parameters_text: 'Параметры',
    comment: 'Комментарий',
    yes: 'Да',
    no: 'Нет',
    true: 'Да',
    false: 'Нет',
    cancel: 'Отменить',
    save: 'Сохранить',
    save_changes: 'Сохранить изменения?',
    my_account: 'Моя учетная запись',
    logout: 'Выйти',
    hour: 'ч',
    hours: 'Часы',
    enter: 'Введите',
    choose: 'Выбрать',
    filter: 'Фильтр',
    signature: 'Подпись',
    plan_type: 'Тип планирования',
    choose_file: 'Выбрать файл',
    load_file: 'Загрузить',
    loading_done: 'Загрузка завершена ',
    loading_import_errors: 'Следующие строки загрузить не удалось: ',
    group: 'Группировка',
    no_results: 'Результаты не найдены',
    days: 'Дней',
    table_error: 'Учёт времени невозможен',
    spent_time_error: 'Учёт невозможен',
    not_found: 'Не найдено',
    selected_fields: 'Выбранные поля',
    available_fields: 'Доступные поля',
    incorrect_url: 'Некорректная ссылка',
    incorrect_homepage_url: 'Введите корректную ссылку для Стартовая страницы',
    incorrect_id:
      'Введите уникальный идентификатор. Допускаются только строчные латинские буквы(a-z), цифры тире и подчеркивание.',
    incorrect_name: 'Введите имя проекта',
    incorrect_field: 'Введите ',
    all_users: 'Всем пользователям',
    back_to_main_page: 'Вернуться на главную',
    search_options: 'Загрузка...',
    edit_text: 'Редактировать',
    delete_text: 'Удалить',
    copy_text: 'Копировать',
    // ERRORS
    error: 'Ошибка',
    error_404: 'Страница не существует',
    error_403: 'Нет доступа',
    error_500: 'Непредвиденная ошибка',
    error_413: 'Превышен объем данных для загрузки',
    // PAGE TITLE
    page_title_login: 'Авторизация',
    page_title_spreadsheet: 'Табель',
    page_title_projects: 'Проекты',
    page_title_projects_settings: 'Настройки проектов',
    page_title_project_reports: 'Отчеты',
    page_title_production_calendar: 'Производственный календарь',
    page_title_spentTime: 'Трудозатраты',
    page_title_spentTime_bulk: 'Редактировать выбранные трудозатраты',
    page_title_issues: 'Задачи',
    page_title_user_settings: 'Настройки',
    page_title_resource_plan: 'Ресурсный план',
    page_title_confirmations: 'Согласования',
    page_title_gant: 'Диаграмма Ганта',
    // GANTT
    gantt_show_all: 'Показать подзадачи',
    gantt_hide_all: 'Скрыть подзадачи',
    // MENU
    menu_item_projects: 'Проекты',
    menu_item_projects_settings: 'Настройки проектов',
    menu_item_project_reports: 'Отчеты',
    menu_item_actions: 'Действия',
    menu_item_tasks: 'Задачи',
    menu_item_work_costs: 'Трудозатраты',
    menu_item_work_costs_my: 'Мои трудозатраты',
    menu_item_work_costs_approval: 'Трудозатраты на утверждение',
    menu_item_work_costs_fact: 'Фактические затраты на утверждение',
    menu_item_diagram: 'Диаграмма Ганта',
    menu_item_confirmations: 'Согласования',
    // menu_item_news: 'Новости',
    menu_item_art: 'ART',
    menu_item_spreadsheet: 'Табель',
    menu_item_resource_plan: 'Ресурсный план',
    menu_item_agile: 'Доска задач',
    menu_item_kanban: 'Kanban доска',
    menu_item_sprint: 'Управление спринтами',
    menu_item_administration: 'Администрирование',
    menu_item_administration_projects: 'Проекты',
    menu_item_administration_users: 'Пользователи',
    menu_item_administration_import: 'Импорт данных',
    menu_item_administration_groups: 'Группы',
    menu_item_administration_permissions: 'Роли и права доступа',
    menu_item_administration_trackers: 'Тип задачи',
    menu_item_administration_task_statuses: 'Статусы задач',
    menu_item_administration_workflows: 'Последовательность действий',
    menu_item_administration_custom_fields: 'Настраиваемы поля',
    menu_item_administration_enumerations: 'Списки значений',
    menu_item_administration_calendar: 'Производственный календарь',
    menu_item_administration_project_reports: 'Отчеты',
    menu_item_administration_settings: 'Настройки',
    menu_item_administration_resourcePlan: 'Настройка Ресурсного плана',
    menu_item_administration_calculationTypes: 'Типы расчета',
    menu_item_administration_contractStages: 'Стадии договоров',
    menu_item_administration_confirmation_settings: 'Настройка Согласований',
    menu_item_administration_gantt_settings: 'Настройка Диаграммы Ганта',
    menu_item_administration_finance_settings: 'Настройка модуля Финансы',
    //  AUTH PAGE
    auth_page_title: 'Авторизация',
    auth_page_login_input_text: 'Имя пользователя',
    auth_page_password_input_text: 'Пароль',
    auth_page_login_label_text: 'Введите e-mail',
    auth_page_password_label_text: 'Введите пароль',
    auth_page_button_text: 'Войти',
    auth_page_remember_checkbox_text: 'Запомнить меня',
    auth_page_forget_pass_text: 'Забыли пароль?',
    auth_page_info_text: 'Для управления проектами, ресурсами и финансами',
    auth_page_language_ru_text: 'Русский',
    select_language_label_text: 'Язык',
    // SPREADSHEET PAGE
    spreadsheet_time_main: 'Основные часы',
    spreadsheet_time_over: 'Сверхурочные',
    spreadsheet_table_clear: 'Очистить',
    spreadsheet_table_project: 'Проект',
    spreadsheet_table_issues: 'Задача',
    spreadsheet_table_mon: 'Пн',
    spreadsheet_table_tue: 'Вт',
    spreadsheet_table_wed: 'Ср',
    spreadsheet_table_thu: 'Чт',
    spreadsheet_table_fri: 'Пт',
    spreadsheet_table_sat: 'Сб',
    spreadsheet_table_sun: 'Вс',
    spreadsheet_table_total: 'Итого',
    spreadsheet_choose_project: 'Выберите проект',
    spreadsheet_choose_task: 'Выберите задачу',
    spreadsheet_overtime: 'Простой/Перегруз',
    spreadsheet_week_sum: 'Итого за неделю',
    spreadsheet_issue_edit: 'Редактирование задачи',
    spreadsheet_projects_null: 'Проекты не найдены',
    spreadsheet_tooltip_hide_text: 'Скрыть строку',
    spreadsheet_tooltip_hide_checkbox: 'Только заполненные',
    // PRODUCTION CALENDAR
    prod_calendar_title: 'Производственный календарь',
    prod_calendar_hours_default: 'Кол-во рабочих часов по умолчанию',
    prod_calendar_days_off: 'Выходные дни',
    prod_calendar_date: 'Дата',
    // ISSUES PAGE
    issues_add: 'Создать задачу',
    // PROJECTS PAGE
    filter_project_project: 'Имя',
    filter_project_name: 'Проект',
    filter_project_description: 'Описание',
    filter_project_calculation_type: 'Тип расчета',
    filter_project_is_public: 'Публичный',
    filter_project_parent: 'Родительский проект',
    filter_project_projects: 'Проекты',
    filter_project_status: 'Статус',
    filter_project_cf_251: 'Тecт bool',
    filter_issue_project: 'Проект',
    ALL: 'Все',
    Opened: 'Открытые',
    Closed: 'Закрытые',
    project_issue_trackerName: '  ',
    project_issue_opened: 'Открыто',
    project_issue_closed: 'Закрыто',
    // SPENT TIME PAGE
    spent_time_type_details: 'Подробности',
    spent_time_type_summary: 'Сводная',
    spent_time_add: 'Добавить трудозатраты',
    spent_time_save: 'Сохранить запрос',
    spent_time_edit: 'Изменить запрос',
    spent_time_delete: 'Удалить запрос',
    spent_time_chosen_fields: 'Выбранные столбцы',
    spent_time_available_fields: 'Доступные столбцы',
    spent_time_totals: 'Итоги',
    spent_time_tree_view: 'Отображать в виде дерева',
    // FILTERS
    filter_timesheet_activity: 'Деятельность',
    filter_timesheet_author: 'Автор',
    filter_timesheet_comments: 'Комментарий',
    filter_timesheet_created_on: 'Дата создания',
    filter_timesheet_hours: 'Часы',
    filter_timesheet_count: 'Количество',
    filter_timesheet_is_approved: 'Утверждено',
    filter_timesheet_issue: 'Задача',
    filter_timesheet_project: 'Проект',
    issue__project: 'Проект',
    filter_timesheet_spent_on: 'Дата',
    filter_timesheet_user: 'Пользователь',
    filter_timesheet_costs: 'Стоимость',
    filter_issue_tracker: 'Тип задачи',
    filter_issue_issue_status: 'Статус',
    filter_issue_last_note: 'Последнее примечание',
    filter_issue_assigned: 'Назначена',
    filter_issue_author: 'Автор',
    filter_issue_attachment: 'Файлы',
    filter_issue_closed: 'Закрыта',
    filter_issue_last_editor: 'Последний изменивший',
    filter_issue_is_private: 'Частная',
    filter_issue_issue: 'Задача',
    filter_issue_sprint: 'Спринт',
    filter_issue_gantt_id: 'ID',
    filter_issue_predecessor: 'Предшественник',
    filter_issue_issue_id: 'Задача (id)',
    filter_issue_is_closed: 'Задача закрыта',
    filter_issue_parent_issue: 'Родительская задача',
    filter_issue_priority: 'Приоритет',
    filter_issue_description: 'Описание',
    filter_issue_created: 'Создано',
    filter_issue_done_ratio: 'Готовность, %',
    filter_issue_loading_percent: 'Загрузка, %',
    filter_issue_end: 'Дата окончания',
    filter_issue_cf_278: 'Трудозатраты факт (полные)',
    filter_issue_cf_210: 'Целевая маржинальность',
    filter_issue_cf_154: 'Целевой CIR',
    filter_issue_cf_295: 'Целевая марж-ть на субподряд',
    filter_issue_cf_283: 'Количество часов',
    filter_issue_cf_274: 'PE Сумма оплачено',
    filter_issue_cf_101: 'Cумма с учетом предв-го upsale',
    filter_issue_cf_123: 'Прочие расходы',
    filter_issue_cf_227: 'Сумма аванса (Contract)',
    filter_issue_cf_106: 'Сумма без НДС по акту',
    filter_issue_cf_296: 'Сумма оплачено',
    filter_issue_cf_88: 'Сумма без НДС',
    filter_issue_cf_128: 'Сумма по договору',
    filter_issue_cf_228: 'Сумма постоплаты (Contract)',
    filter_issue_cf_240: 'Стоимость 2',
    filter_issue_cf_243: 'Оценка трудозатрат для заказч.',
    filter_issue_sprint_start: 'Начало спринта',
    filter_issue_cf_186: 'Задержка, дн.',
    filter_issue_cf_301: 'Кол-во ТК план',
    filter_issue_cf_195: 'Признано PE',
    filter_issue_cf_302: 'Кол-во ТК факт',
    filter_issue_cf_115: 'Признано',
    filter_issue_cf_254: 'Оценка (времени)',
    filter_issue_estimated_hours: 'Трудозатраты план, ч',
    filter_issue_start: 'Дата начала',
    filter_issue_watcher: 'Наблюдатели',
    filter_issue_deadline_changed: 'Срок изменён',
    filter_issue_additional_time: 'Дополнительное время',
    filter_issue_estimated_value: 'Стоимость план',
    filter_issue_planned_date: 'Плановая дата',
    filter_issue_issue_sprint: 'Спринт',
    filter_users_group: 'Группа',
    filter_users_role: 'Роль',
    filter_status: 'Статус',
    filter_user_list: 'Пользователь',
    filter_issue_updated: 'Обновлено',
    filter_issue_calculation_type: 'Тип расчета',
    filter_fieldName: 'Тип задачи',
    filter_issue_subject: 'Название задачи',
    filter_issue_duration: 'Длительность, дн',
    filter_predecessor: 'Предшественник',
    filter_issue_status: 'Статус задачи',
    filter_art_acceptance_plan_date: 'Дата приёмки, план',
    filter_art_prepayment_plan_date: 'Дата аванса, план',
    filter_art_act_transfer_plan_date: 'Дата передачи акта, план',
    filter_art_act_transfer_fact_date: 'Дата передачи акта, факт',
    filter_art_act_sign_plan_date: 'Дата подписания акта, план',
    filter_art_act_sign_fact_date: 'Дата подписания акта, факт',
    filter_art_bill_transfer_plan_date: 'Дата передачи счета, план',
    filter_art_bill_transfer_fact_date: 'Дата передачи счета, факт',
    filter_art_payment_plan_date: 'Дата оплаты, план',
    filter_art_payment_fact_date: 'Дата оплаты, факт',
    filter_art_contract_sign_date: 'Дата подписания договора',
    // ERRORS
    error_unauthorized: 'Неверный логин или пароль',
    error_unknown: 'Неизвестная ошибка, попробуйте еще раз позже',
    // SETTINGS
    settings_tasktype_type: 'Тип задачи',
    settings_tasktype_placeholder: 'Поле',
    settings_tasktype_before: 'Было',
    settings_tasktype_after: 'Стало',
    settings_tasktype_change_date: 'Дата изменения',
    settings_tasktype_author: 'Автор изменения',
    settings_task_header: 'Задачи',
    settings_task_name: 'Все',
    settings_task_opened: 'Открыто',
    settings_task_closed: 'Закрыто',
    settings_project_header: 'Проекты',
    settings_project_name: 'Проект',
    settings_project_role: 'Роли',
    settings_project_reg_date: 'Зарегистрирован(а)',
    // RESOURCE PLAN
    Project: 'Проект',
    Resource: 'Ресурс',
    resource_plan_settings: 'Настройка Ресурсного плана',
    resplan_settings_choose: 'Выберите',
    resplan_settings_contract_tracker: 'Тип задачи для Договора',
    resplan_settings_role_resource: 'Поле Ролевой ресурс',
    resplan_settings_dismissals_date: 'Поле Дата увольнения',
    resplan_settings_department: 'Поле Подразделение',
    resplan_settings_user_availiability: 'Поле Доступность',
    resplan_settings_business_unit: 'Поле Бизнес-юнит',
    resplan_settings_tracker_header: 'Тип задачи ресурсного планирования',
    resplan_settings_employment_check: 'Разрешить проверку занятости',
    resplan_settings_over_employment: 'Запрет превышения занятости',
    resplan_settings_access_role: 'Права доступа',
    filter_resourcePlan_business_unit: 'Бизнес-юнит',
    filter_resourcePlan_date_to: 'Дата по:',
    filter_resourcePlan_date_from: 'Дата с:',
    filter_resourcePlan_department: 'Подразделение',
    filter_resourcePlan_user_list: 'Сотрудник',
    filter_resourcePlan_contract_issues: 'Договор / Заказ',
    filter_resourcePlan_issues: 'Задача',
    filter_issues_project_list: 'Проект',
    filter_issue_project_list: 'Проект',
    filter_resourcePlan_project_list: 'Проект',
    filter_resourcePlan_apply_tooltip: 'Применить фильтр',
    resPlan_loading_percent: 'Загрузка, %',
    resPlan_user: 'Ресурс',
    resPlan_project: 'Проект',
    resPlan_contract: 'Договор',
    resPlan_issue: 'Задача',
    resPlan_date_to: 'Окончание',
    resPlan_date_from: 'Начало',
    resplan_table_total: 'Итого',
    resplan_table_perload: 'Простой/Перегруз',
    resPlan_tracker: 'Тип задачи',
    resPlan_monthly_distribution: 'Распределение по месяцам',
    resPlan_cost_fact: 'Стоимость факт',
    resPlan_labor_fact: 'Трудозатраты факт, ч',
    resPlan_accept_saving: 'Принять',
    resPlan_cash_save_message: 'Есть несохраненные изменения.',
    resPlan_cash_save_question: 'Сохранить?',
    resPlan_is_loading: 'Загрузка данных...',
    resplan_no_data: 'Данные не найдены',
    resPlan_dateFrom_error: 'Начало должно быть ранее завершения.',
    resPlan_dateTo_error: 'Окончание должно быть позднее начала.',
    resPlan_emptyFields_error: 'Не заполнены обязательные поля.',
    resPlan_dateFormat_error: 'Некорректный формат даты.',
    resPlan_no_issue_column: 'Для создания задачи добавьте колонку "Задача"',
    resPlan_add_new_issue: 'Создать задачу',
    // Users page
    filter_users_user: 'Пользователь',
    filter_users_first_name: 'Имя',
    filter_users_last_name: 'Фамилия',
    filter_users_email: 'Email',
    filter_users_admin: 'Администратор',
    filter_users_create_date: 'Создано',
    filter_users_last_connection: 'Последнее подключение',
    users_page_common: 'Общее',
    users_page_rates: 'Ставки',
    users_page_common_tab_information: 'Информация',
    users_filter_Active: 'активен',
    users_filter_Registered: 'зарегистрирован',
    users_filter_Blocked: 'заблокирован',
    // User page
    user_page_login: 'Пользователь',
    user_page_firstName: 'Имя',
    user_page_lastName: 'Фамилия',
    user_page_email: 'E-mail',
    user_page_rates_calculation_type: 'Тип расчета ',
    user_page_rates_rate: 'Ставка',
    user_page_rates_date: 'Действует с ',
    user_page_rate_error: 'Ставка не уникальна по типу расчета и дате действует с',
    // Pagination
    pagination_next: 'Следующая',
    pagination_previous: 'Предыдущая',
    // Issue page
    create_issue_agreement: 'Создать согласование',
    issue_clear_tooltip: 'Очистить',
    issue_new_title: 'Новая задача',
    issue_edit_title: 'Редактировать задачу',
    issue_edit_bulk_title: 'Редактировать выбранные задачи',
    issue_add: 'Добавил(а)',
    issue_page_estimated_value: 'Стоимость план',
    issue_page_duration: 'Длительность, дн',
    issue_page_sprint: 'Спринт',
    issue_page_files_title: 'Файлы',
    issue_page_watchers_title: 'Наблюдатели',
    issue_page_children_title: 'Подзадачи',
    issue_page_confirmations_title: 'Согласования',
    issue_page_confirmations_confirmation: 'Согласования',
    issue_page_confirmations_status: 'Статус',
    issue_page_confirmations_createdBy: 'Автор',
    issue_page_confirmations_createdOn: 'Дата создания',
    issue_page_related_title: 'Связанные задачи',
    issue_page_contract_stages_title: 'Стадии договоров',
    issue_page_history_title: 'История',
    issue_page_children_subject: 'Подзадача',
    issue_page_children_issue_status: 'Статус',
    issue_page_children_assigned: 'Назначена',
    issue_page_children_start: 'Начало',
    issue_page_children_end: 'Завершение',
    issue_page_watchers_placeholder: 'Введите наблюдателя',
    issue_page_related_task_label: 'Задача',
    issue_page_related_task_delay: 'Отложить',
    issue_page_related_task_days: 'дней(я)',
    issue_page_status: 'Статус',
    issue_page_due_date: 'Дата окончания',
    issue_page_estimated_time: 'Трудозатраты план, ч',
    issue_page_spent_time: 'Трудозатраты факт, ч',
    issue_page_project: 'Проект',
    issue_page_tracker: 'Тип задачи',
    issue_page_subject: 'Название',
    issue_page_issue_status: 'Статус',
    issue_page_priority: 'Приоритет',
    issue_page_is_private: 'Частная',
    issue_page_private: 'Частная',
    issue_page_assignee: 'Назначена',
    issue_page_assigned_to: 'Назначена',
    issue_page_category: 'Категория',
    issue_page_version: 'Версия',
    issue_page_parent_task: 'Родительская задача',
    issue_page_calculation_type: 'Тип расчета',
    issue_page_parent_issue: 'Родительская задача',
    issue_page_start_date: 'Дата начала',
    issue_page_end_date: 'Дата окончания',
    issue_page_estimated_hours: 'Оценка временных затрат',
    issue_page_completion: 'Готовность',
    issue_page_description: 'Описание',
    issue_page_plan_cost: 'Стоимость план',
    issue_page_created: 'Создано',
    issue_page_updated: 'Обновлено',
    issue_page_closed: 'Закрыто',
    issue_page_author: 'Автор',
    issue_page_percent_done: 'Готовность, %',
    issue_page_target_version: 'Версия',
    issue_page_notes: 'Примечание',
    issue_page_error: 'Не заполнены обязательные поля.',
    issue_related_error: 'Невозможно установить связь между задачами',
    issue_page_error_url_error: 'URL должен быть URL-адресом',
    issue_page_acceptance_plan_date: 'Дата приёмки, план',
    issue_page_prepayment_plan_date: 'Дата аванса, план',
    issue_page_act_transfer_plan_date: 'Дата передачи акта, план',
    issue_page_act_transfer_fact_date: 'Дата передачи акта, факт',
    issue_page_act_sign_plan_date: 'Дата подписания акта, план',
    issue_page_act_sign_fact_date: 'Дата подписания акта, факт',
    issue_page_bill_transfer_plan_date: 'Дата передачи счета, план',
    issue_page_bill_transfer_fact_date: 'Дата передачи счета, факт',
    issue_page_payment_plan_date: 'Дата оплаты, план',
    issue_page_payment_fact_date: 'Дата оплаты, факт',
    issue_page_contract_sign_date: 'Дата подписания договора',
    issue_page_doneratio: 'Готовность',
    // Issue status select
    issue_page_copied_to: 'Скопирована в',
    issue_page_precedes: 'Следующая',
    issue_page_blocked_by: 'Блокируется',
    issue_page_has_duplicate: 'Дублируется',
    issue_page_copied_from: 'Скопирована с',
    issue_page_blocks: 'Блокирует',
    issue_page_follows: 'Предыдущая',
    issue_page_is_duplicate_of: 'Дублирует',
    issue_page_relates: 'Связана с',
    issue_page_related_to: 'Связана с',
    // Issue form
    issue_project_label: 'Проект',
    issue_tracker_label: 'Тип задачи',
    //Issue tabs
    issue_tab_history: 'История',
    issue_tab_contract_stages: 'Стадии договоров',
    //Issue history
    issue_page_file: 'Файл',
    issue_page_deleted: 'удален(о)',
    issue_page_value: 'Значение',
    issue_page_of_parameter: 'параметра',
    issue_page_added: 'добавлен',
    issue_page_parameter: 'Параметр',
    issue_page_changed_to: 'изменился на',
    issue_page_changed: 'изменился',
    issue_page_changed_from: 'изменился c',
    issue_page_to: 'на',
    //Contract stages
    contract_stages_trackers_title: 'Тип задачи со стадиями договоров',
    contract_stages_customers_title: 'Поле заказчик',
    contract_stages_roles_title: 'Доступ к стадиям договора',
    contract_stages_customer: 'Заказчик',
    contract_stages_field: 'Поле стадий договора',
    issue_contract_stage_customer: 'Заказчик',
    issue_contract_stage_author: 'Автор',
    issue_contract_stage_stage: 'Стадии договора',
    issue_contract_stage_start_date: 'Начало процесса',
    issue_contract_stage_comment: 'Комментарий',
    //Agile
    agile_title: 'Доска задач',
    agile_create_board: 'Создать доску',
    agile_edit_board: 'Редактировать доску',
    agile_delete_board: 'Удалить доску',
    agile_tasks_status: 'Статус задач',
    agile_modal_signature: 'Подпись',
    agile_modal_planTypes: 'Тип планирования',
    // Confirmation Settings
    confirm_settings_business_unit: 'Бизнес-юнит',
    confirm_settings_initial_state_of_document_confirmation: 'Начальное состояние согласования документа',
    confirm_settings_initial_state_of_document_changes_confirmation: 'Начальное состояние согласования изменений',
    confirm_settings_final_state_of_document_confiramtion: 'Финальное состояние',
    confirm_settings_tracker_of_document_confirmation: 'Тип задачи согласования документов',
    confirm_settings_tracker_of_document_changes_confirmation: 'Тип задачи согласования изменений',
    confirm_settings_send_emails: 'Рассылка уведомлений',
    confirm_settings_approval_role: 'Роль для процесса согласования',
    confirm_settings_cancellation_role: 'Роль для отмены согласования',
    confirm_settings_intermediate_state_of_document_confirmation: 'Статус на согласовании',
    confirm_settings_roles_that_can_initialize_confirmation: 'Инициализация согласований',
    confirm_settings_roles_that_can_view_confirmations: 'Просмотр согласований',
    confirmation_trackers_tracker: 'Тип задачи документа',
    confirmation_trackers_url: 'Отчет',
    confirmation_trackers_company_group: 'Бизнес-юнит',
    confirmation_trackers_group_one: 'Группа 1',
    confirmation_trackers_group_two: 'Группа 2',
    confirmation_trackers_group_three: 'Группа 3',
    confirmation_trackers_url_error: 'URL должен быть URL-адресом',
    confirmation_trackers_save_error: 'Для заданного документа и бизнес юнита путь согласования уже существует',
    // Confirmation page
    confirmation_page_title: 'Настройка Согласований',
    confirmation_page_created_by: 'Создано',
    confirmation_page_status: 'Статус',
    confirmation_page_created_on: 'Дата создания',
    confirmation_page_canceled_by: 'Отменено',
    confirmation_page_updated_on: 'Обновлено',
    confirmation_page_updated_by: 'Обновил(а)',
    confirmation_page_is_closed: 'Закрыто',
    confirmation_page_document_type: 'Тип документа',
    confirmation_page_documents: 'Документ',
    confirmation_page_field_name: 'Название',
    confirmation_page_field_origin_value: 'Значение',
    confirmation_page_origin_reinit: 'Значение до',
    confirmation_page_field_renew_value: 'Значение после',
    confirmation_document_button: 'Согласовать документ',
    confirmation_reinit_button: 'Согласовать изменения',
    confirmation_result_title: 'Результат согласования',
    confirmation_page_approver: 'Согласующий',
    confirmation_page_result: 'Результат',
    confirmation_page_comment: 'Комментарий',
    confirmation_page_result_approved: 'Согласовано',
    confirmation_page_result_cancelled: 'Отклонено',
    confirmation_page_report: 'Отчет',
    confirmation_page_project: 'Проект',
    confirmation_result_save_error: 'Укажите в комментарии причину отклонения',
    confirmation_result_save_result_error: 'Поле результат не может быть пустым',
    confirmation_fix: 'Обновить документ',
    // Confirmations page
    confirmations_page_project: 'Проект',
    confirmations_page_confirmation: 'Согласование',
    confirmations_page_status: 'Статус согласования',
    confirmations_page_trackers: 'Тип задачи согласования',
    confirmations_page_unapprover: 'Не рассмотрели',
    confirmations_page_document_type: 'Тип документа',
    confirmations_page_document: 'Документ',
    confirmations_page_created_by: 'Автор',
    confirmations_page_updated_by: 'Последний изменивший',
    confirmations_page_created_on: 'Создан',
    confirmations_page_approver: 'Согласующий',
    confirmations_page_is_initial: 'Начальный',
    confirmations_page_updated_on: 'Обновлено',
    filter_confirmation_project: 'Проект',
    filter_confirmation_confirmation: 'Согласование',
    filter_confirmation_status: 'Статус согласования',
    filter_confirmation_unapprover: 'Не рассмотрели',
    filter_confirmation_documentType: 'Тип документа',
    filter_confirmation_document: 'Документ',
    filter_confirmation_createdBy: 'Автор',
    filter_confirmation_updatedBy: 'Последний изменивший',
    filter_confirmation_createdOn: 'Создан',
    filter_confirmation_approver: 'Согласующий',
    filter_confirmation_isInitial: 'Начальный',
    filter_confirmation_updatedOn: 'Обновлено',
    filter_confirmation_report: 'Отчет',
    // Confirmations documents titles
    confirmation_documents_status: 'Статус',
    confirmation_documents_due_date: 'Дата окончания',
    confirmation_documents_estimated_time: 'Трудозатраты план, ч',
    confirmation_documents_spent_time: 'Трудозатраты факт, ч',
    confirmation_documents_project: 'Проект',
    confirmation_documents_tracker: 'Тип задачи',
    confirmation_documents_subject: 'Название',
    confirmation_documents_priority: 'Приоритет',
    confirmation_documents_is_private: 'Частная',
    confirmation_documents_private: 'Частная',
    confirmation_documents_assignee: 'Назначена',
    confirmation_documents_assigned_to: 'Назначена',
    confirmation_documents_category: 'Категория',
    confirmation_documents_version: 'Версия',
    confirmation_documents_parent_task: 'Родительская задача',
    confirmation_documents_calculation_type: 'Тип расчета',
    confirmation_documents_parent_issue: 'Родительская задача',
    confirmation_documents_start_date: 'Дата начала',
    confirmation_documents_end_date: 'Дата окончания',
    confirmation_documents_estimated_hours: 'Оценка временных затрат',
    confirmation_documents_completion: 'Готовность',
    confirmation_documents_description: 'Описание',
    confirmation_documents_plan_cost: 'Стоимость план',
    confirmation_documents_created: 'Создано',
    confirmation_documents_updated: 'Обновлено',
    confirmation_documents_closed: 'Закрыто',
    confirmation_documents_author: 'Автор',
    confirmation_documents_percent_done: 'Готовность, %',
    confirmation_documents_target_version: 'Версия',
    confirmation_documents_notes: 'Примечание',
    // Confirmation result
    confirmation_result_approved: 'Согласовано',
    confirmation_result_rejected: 'Отклонено',
    //Filter operations
    operator_in: 'содержит',
    operator_notin: 'не содержит',
    operator_startswith: 'начинается с',
    operator_endswith: 'заканчивается на',
    operator_null: 'отсутствует',
    operator_all: 'все',
    operator_eq: 'соответствует',
    operator_noteq: 'не соответствует',
    operator_gte: '>=',
    operator_lte: '<=',
    operator_btw: 'между',
    operator_today: 'сегодня',
    operator_yesterday: 'вчера',
    operator_thisWeek: 'на этой неделе',
    operator_lastWeek: 'прошлая неделя',
    operator_thisMonth: 'этот месяц',
    operator_lastMonth: 'прошлый месяц',
    operator_thisYear: 'этот год',
    filters_select_assigned: 'Назначена',
    filters_select_tracker: 'Тип задачи',
    filters_select_parent_issue: 'Родительская задача',
    filters_select_project: 'Проект',
    filters_select_confirmation: 'Согласование',
    filters_select_status: 'Статус',
    filters_select_trackers: 'Тип задачи согласования',
    filters_select_unapprover: 'Не рассмотрели',
    filters_select_document_type: 'Тип документа',
    filters_select_document: 'Документ',
    filters_select_created_by: 'Автор',
    filters_select_updated_by: 'Последний изменивший',
    filters_select_created_on: 'Создан',
    filters_select_approver: 'Согласующий',
    filters_select_is_initial: 'Начальный',
    filters_select_updated_on: 'Обновлено',
    filters_select_last_note: 'Последнее примечание',
    filters_select_author: 'Автор',
    filters_select_attachment: 'Файлы',
    filters_select_closed: 'Закрыта',
    filters_select_last_editor: 'Последний изменивший',
    filters_select_is_private: 'Частная',
    filters_select_issue: 'Задача',
    filters_select_sprint: 'Спринт',
    filters_select_gantt_id: 'ID',
    filters_select_issue_id: 'Задача (id)',
    filters_select_is_closed: 'Задача закрыта',
    filters_select_priority: 'Приоритет',
    filters_select_description: 'Описание',
    filters_select_created: 'Создано',
    filters_select_done_ratio: 'Готовность, %',
    filters_select_loading_percent: 'Загрузка, %',
    filters_select_end: 'Дата окончания',
    //filters_select_cf_278: 'Трудозатраты факт (полные)',
    //filters_select_cf_210: 'Целевая маржинальность',
    //filters_select_cf_154: 'Целевой CIR',
    //filters_select_cf_295: 'Целевая марж-ть на субподряд',
    //filters_select_cf_283: 'Количество часов',
    //filters_select_cf_274: 'PE Сумма оплачено',
    //filters_select_cf_101: 'Cумма с учетом предв-го upsale',
    //filters_select_cf_123: 'Прочие расходы',
    //filters_select_cf_227: 'Сумма аванса (Contract)',
    //filters_select_cf_106: 'Сумма без НДС по акту',
    //filters_select_cf_296: 'Сумма оплачено',
    //filters_select_cf_88: 'Сумма без НДС',
    //filters_select_cf_128: 'Сумма по договору',
    //filters_select_cf_228: 'Сумма постоплаты (Contract)',
    //filters_select_cf_240: 'Стоимость 2',
    //filters_select_cf_243: 'Оценка трудозатрат для заказч.',
    filters_select_sprint_start: 'Начало спринта',
    //filters_select_cf_186: 'Задержка, дн.',
    //filters_select_cf_301: 'Кол-во ТК план',
    //filters_select_cf_195: 'Признано PE',
    //filters_select_cf_302: 'Кол-во ТК факт',
    //filters_select_cf_115: 'Признано',
    //filters_select_cf_254: 'Оценка (времени)',
    filters_select_estimated_hours: 'Трудозатраты план, ч',
    filters_select_start: 'Дата начала',
    filters_select_watcher: 'Наблюдатели',
    filters_select_deadline_changed: 'Срок изменён',
    filters_select_additional_time: 'Дополнительное время',
    filters_select_estimated_value: 'Стоимость план',
    filters_select_planned_date: 'Плановая дата',
    filters_select_issue_sprint: 'Спринт',
    filters_select_users_group: 'Группа',
    filters_select_users_role: 'Роль',
    filters_select_user_list: 'Пользователь',
    filters_select_updated: 'Обновлено',
    filters_select_calculation_type: 'Тип расчета',
    filters_select_fieldName: 'Тип задачи',
    filters_select_subject: 'Название задачи',
    filters_select_duration: 'Длительность, дн',
    filters_select_predecessor: 'Предшественник',
    filters_select_issue_status: 'Статус задачи',
    filters_select_group: 'Группа',
    filters_select_role: 'Роль',
    filters_select_hours: 'Часы',
    filters_select_activity: 'Деятельность',
    filters_select_comments: 'Комментарий',
    filters_select_spent_on: 'Дата',
    filters_select_is_approved: 'Утверждено',
    filters_select_user: 'Пользователь',
    filters_select_costs: 'Стоимость',
    filters_select_acceptance_plan_date: 'Дата приёмки, план',
    filters_select_prepayment_plan_date: 'Дата аванса, план',
    filters_select_act_transfer_plan_date: 'Дата передачи акта, план',
    filters_select_act_transfer_fact_date: 'Дата передачи акта, факт',
    filters_select_act_sign_plan_date: 'Дата подписания акта, план',
    filters_select_act_sign_fact_date: 'Дата подписания акта, факт',
    filters_select_bill_transfer_plan_date: 'Дата передачи счета, план',
    filters_select_bill_transfer_fact_date: 'Дата передачи счета, факт',
    filters_select_payment_plan_date: 'Дата оплаты, план',
    filters_select_payment_fact_date: 'Дата оплаты, факт',
    filters_select_contract_sign_date: 'Дата подписания договора',
    signature_subject: 'Название',
    signature_loading_percent: 'Загрузка, %',
    signature_assigned: 'Назначена',
    signature_estimated_hours: 'Трудозатраты план, ч',
    signature_duration: 'Длительность, дн',
    signature_done_ratio: 'Готовность',
    signature_extended_subject: 'Задача',
    access_roles: 'Права доступа',
    import_data_type: 'Тип данных',
    context_menu_edit: 'Редактировать',
    context_menu_move: 'Переместить',
    context_menu_delete: 'Удалить',
    context_menu_after: 'После задачи',
    saving_gantt: 'Сохранить мои изменения',
    clearing_gantt: 'Отменить мои изменения',
    issue_page_predecessor: 'Предшественник',
    issue_page_loadingpercent: 'Загрузка, %',
    gantt_issue_modal_date_error: 'Завершение должно быть позже даты начала',
    gantt_start_date_error: 'Дата начала не может быть раньше даты окончания предшественника',
    //Gantt Errors
    gant_error_assignedUser: 'Назначена',
    gant_error_endDate: 'Дата окончания',
    gant_error_estimatedHours: 'Трудозатраты план, ч',
    gant_error_startDate: 'Дата начала',
    error_task_text: 'по задаче',
    error_task_text_change_from: 'сменился с',
    error_task_text_change_to: 'на',
    gantt_settings_page_title: 'Настройка Диаграммы Ганта',

    //Grid params fields name
    params_filters: 'Фильтр',
    params_groups: 'Группировка',
    params_totals: 'Итоги',
    params_sorts: 'Отображать в виде дерева',

    //Art
    finance_page_title: 'Настройка модуля Финансы',
  },
};
