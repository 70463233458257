import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Box, useMediaQuery } from '@mui/material';
import NotificationIcon from 'Common/shared-ui/src/icons/NotificationIcon';
import FaqIcon from 'Common/shared-ui/src/icons/FaqIcon';

import useStyles from './HeaderNavIcons.styles';

const HeaderNavIcons = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('sm'));
  return (
    <Box display="flex" flexWrap="nowrap" alignItems="center" className={classes.HeaderIconsRoot}>
      <Link to="/" className={clsx(classes.icon, classes.notificationIcon)}>
        <NotificationIcon viewBox="0 0 24 24" />
      </Link>
      {!isSmallScreen && (
        <a
          target={'_blank'}
          href={
            'https://xwiki.rbdata.group/wiki/usermanual/view/Main/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%B0%D1%8F%20%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%201/'
          }
          className={classes.icon}
        >
          <FaqIcon className={classes.faqIcon} viewBox="0 0 24 24" />
        </a>
      )}
    </Box>
  );
};

export default HeaderNavIcons;
