import { injectIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { getUsersData, getUsersFilters } from '../../../../packages/common/api';
import SortIcon from '../../../../packages/common/shared-ui/src/icons/SortIcon';
import {
  DataGridPro,
  gridPageCountSelector,
  GridPagination,
  useGridSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Popper,
  FormControl,
  Select,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import useStyles from './ImportNewData.styles';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import { Link } from 'react-router-dom';
import ClearIcon from '../../../../packages/common/shared-ui/src/icons/ClearIcon';
import MuiPagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import PaginationNextButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationNextButtonIcon';
import PaginationPreviousButtonIcon from '../../../../packages/common/shared-ui/src/icons/PaginationPreviousButtonIcon';
import userPageStyles from '../userPage/UserPage.styles';
import ImportNewDataStyles from './ImportNewData.styles';
import { postImportData } from '../../../../packages/common/api';
import clsx from 'clsx';
const ImportNewData = ({ intl }) => {
  const classes = useStyles();
  const commonClasses = userPageStyles();
  const fieldsClasses = ImportNewDataStyles();
  const pageTitle = intl.formatMessage({ id: 'menu_item_administration_import' });
  const doneMessage = intl.formatMessage({ id: 'loading_done' });
  const loadingImportErrors = intl.formatMessage({ id: 'loading_import_errors' });
  const [dataType, setDataType] = useState('');
  const [fileData, setFileData] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [locked, setLocked] = useState(false);
  const [dataTypeOptions, setDataTypeOptions] = useState([
    /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
    { value: 'timesheet', valueName: 'Трудозатраты' },
    /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
    { value: 'issue', valueName: 'Задачи' },
    /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
    { value: 'user', valueName: 'Пользователи' },
    /* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */
    { value: 'project', valueName: 'Проекты' },
  ]);
  const [loading, setLoading] = useState(false);
  const handleStartImport = useCallback(
    async event => {
      setLocked(true);
      setSuccess('');
      setError('');
      console.log('handleFileUpload');
      console.log(event);
      console.log(dataType);
      console.log(fileData);
      try {
        const { data: response } = await postImportData(dataType, fileData);
        if (response) {
          if ((response.status = 'ok')) {
            setSuccess(doneMessage);
          }
          if (response.errorRows.length > 0) {
            setError(loadingImportErrors + response.errorRows.join(','));
          }
          setLocked(false);
          setDataType('');
          setFileData('');
          console.log(response);
        }
      } catch (error) {
        console.error('ERROR WITH SAVE', error);
        setLocked(false);
      }
    },
    [dataType, fileData, loadingImportErrors, doneMessage],
  );
  return (
    <Page title={pageTitle} width="100%">
      <Grid container spacing={5} sx={{ mb: '30px' }}>
        <Grid item>
          <Typography m={0} padding={'10px 0'} variant="h1">
            {pageTitle}
          </Typography>
        </Grid>
      </Grid>
      <Box
        className={commonClasses.blockWrapper}
        padding={'18px 28px 20px 28px'}
        marginTop={'16px'}
        position={'relative'}
      >
        <Grid container>
          <Grid item>
            {success && (
              <Typography variant="h5" color="#37C670">
                {success}
              </Typography>
            )}
            {error && (
              <Typography variant="h5" color="#E03737">
                {error}
              </Typography>
            )}
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '300px',
                marginTop: '16px',
                flex: 1,
              }}
            >
              <Typography variant="h5" color={'#41424E'}>
                {`${intl.formatMessage({
                  id: 'import_data_type',
                })}`}
              </Typography>
              <Select
                IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                className={clsx(fieldsClasses.filterSelect, fieldsClasses.filterSelectMultiple)}
                value={dataType ? dataType : ''}
                onChange={event => setDataType(event.target.value)}
                MenuProps={{ className: classes.filterPopoverSelectMenu }}
              >
                {dataTypeOptions?.map((item, index) => (
                  <MenuItem value={item.value} key={index}>
                    <Typography variant="h5" color="#212346">
                      {item.valueName}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container alignItems={'center'} marginTop={'16px'}>
            {fileData?.name}
            <Button
              variant="defaultGreyPrimary"
              component="label"
              sx={{ marginLeft: '10px', width: '115px', padding: '6px 16px' }}
            >
              <Typography variant="h5">{intl.formatMessage({ id: 'choose_file' })}</Typography>
              <input hidden multiple type="file" onChange={event => setFileData(event.target.files[0])} />
            </Button>
          </Grid>
          <Grid container alignItems={'center'} marginTop={'16px'}>
            {dataType && fileData.name && (
              <Button
                variant="defaultGreyPrimary"
                component="label"
                disabled={locked}
                sx={{ marginLeft: '10px', width: '115px', padding: '6px 16px' }}
                onClick={event => handleStartImport()}
              >
                <Typography variant="h5">{intl.formatMessage({ id: 'load_file' })}</Typography>
              </Button>
            )}
            {loading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="30px"
                height="30px"
                marginLeft={'10px'}
              >
                <CircularProgress size={20} color="secondary" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default injectIntl(ImportNewData);
