import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import Page from 'Common/shared-ui/src/components/Page';
import LeftContainer from '../LeftContainer';
import useStyles from './AuthContainer.styles';
import { Grid, useMediaQuery } from '@mui/material';
import RightContainer from '../RightContainer';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import cookies from 'Common/utils/cookies';
import { useQueryParams } from 'use-query-params';

const AuthContainer = ({ intl, userInfo, redmineToken }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pageTitle = intl.formatMessage({ id: 'page_title_login' });
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.maxWidth('md'));
  const [backUrl, setBackUrl] = useState(window.localStorage.getItem('back_url'));
  const [query, setQuery] = useQueryParams({
    back_url: '',
    redmine_token: '',
  });

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (cookies.getCookie('access_token')) {
      window.location.href = query.back_url ? query.back_url : generateRedirectUrl({ host: window.location.origin });
      return;
    }
    let newBackUrl = backUrl;
    if (backUrl === '/logout') {
      newBackUrl = '/';
    }
    window.location.href = generateRedirectUrl({
      host: window.location.origin,
      redmineToken,
      isWithAuth: true,
      backUrl: newBackUrl,
    });
  }, [userInfo, navigate, redmineToken, query.back_url, backUrl]);

  return (
    <Page title={pageTitle} className={classes.AuthContainerRoot}>
      <Grid
        container
        wrap="nowrap"
        direction={isSmallScreen ? 'column-reverse' : 'row'}
        alignItems={isSmallScreen && 'center'}
        height="100%"
      >
        <LeftContainer />
        <RightContainer />
      </Grid>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    redmineToken: state.user.redmine_access_token,
    userInfo: state.user.info,
    isUserLoading: state.user.isLoading,
  };
};

export default connect(mapStateToProps)(injectIntl(AuthContainer));
