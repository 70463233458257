import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import {
  deleteIssueRelated,
  getIssueRelated,
  getQueryIssues,
  getQueryIssuesParams,
  saveIssueRelated,
} from '../../../../../packages/common/api';
import { Link, useParams } from 'react-router-dom';
import issueRelatedStyles from './IssueRelated.styles';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import { relationsSelectValues } from './relationsSelectValues';
import issuePageStyles from '../../IssuePage.styles';

const IssueRelated = ({ intl, permissions }) => {
  const params = useParams();
  const classes = issueRelatedStyles();
  const issueCommonStyles = issuePageStyles();
  const [related, setRelated] = useState([]);
  const [showAddRelated, setShowAddRelated] = useState(false);
  const [relatedIssueOptions, setRelatedIssueOptions] = useState([]);
  const [relatedIssueLoading, setRelatedIssueLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function getRelated() {
      const { data: related } = await getIssueRelated(params.id);
      if (!ignore) {
        return related;
      }
    }
    getRelated()
      .then(res => {
        setRelated(res);
      })
      .catch(error => console.error(error, 'ERROR IN GET ISSUE'));
    return () => {
      ignore = true;
    };
  }, [params.id]);

  const handleRelatedIssueChange = useCallback(
    async value => {
      setRelatedIssueLoading(true);
      let query = {
        search: value,
        related: params.id,
      };
      try {
        const response = await getQueryIssuesParams(query);
        if (response) {
          setRelatedIssueLoading(false);
          setRelatedIssueOptions(response.data);
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [params.id],
  );

  const handleRelationSelect = useCallback(
    (event, index) => {
      const delay = ['precedes', 'follows'];
      let updatedObject = { ...related[index], relationType: event.target.value, relatedIssueId: '' };
      !updatedObject.canDelay && delay.includes(event.target.value) && (updatedObject.canDelay = true);
      if (updatedObject.canDelay && !delay.includes(event.target.value)) {
        delete updatedObject.canDelay;
        delete updatedObject.delay;
      }
      setRelated([...related.slice(0, index), updatedObject, ...related.slice(index + 1)]);
    },
    [related],
  );
  const handleRelatedIssueSelect = useCallback(
    (value, index) => {
      const updatedObject = { ...related[index], relatedIssueId: value };
      setRelated([...related.slice(0, index), updatedObject, ...related.slice(index + 1)]);
    },
    [related],
  );
  const handleTextField = useCallback(
    (event, type, index) => {
      const updatedObject = { ...related[index], [type]: event.target.value };
      setRelated([...related.slice(0, index), updatedObject, ...related.slice(index + 1)]);
    },
    [related],
  );
  const handleNumberField = useCallback(
    (event, type, field) => {
      const reg = new RegExp(/^[0-9-]*$/);
      if (reg.test(event.target.value)) {
        handleTextField(event, type, field);
      }
    },
    [handleTextField],
  );

  const handleSaveIssueRelated = useCallback(
    async index => {
      if (!related[index].relatedIssueId || !related[index].relationType) {
        return;
      }
      let data = {
        relatedIssueId: related[index].relatedIssueId.valueId,
        relationType: related[index].relationType,
      };
      related[index].delay && (data.delay = related[index].delay);
      try {
        const response = await saveIssueRelated(params.id, data);
        let updatedObject = { ...related[index] };
        if (response) {
          delete updatedObject.new;
          const { data: related } = response;
          setRelated(related);
          setShowAddRelated(false);
          setError(false);
        }
      } catch (error) {
        error.response.status === 500 && setError(true);
        console.error('ERROR WITH SAVE', error);
      }
    },
    [params.id, related],
  );

  const handleDeleteIssueRelated = useCallback(
    async index => {
      if (related[index].new) {
        const newArray = related.filter((value, i) => i !== index);
        setRelated(newArray);
        setShowAddRelated(false);
        return;
      }
      try {
        const response = await deleteIssueRelated(params.id, related[index].relationId);
        if (response) {
          const newArray = related.filter((value, i) => i !== index);
          setRelated(newArray);
        }
      } catch (error) {
        console.error('ERROR WITH DELETE', error);
      }
    },
    [params.id, related],
  );
  const handleShowAddRelated = useCallback(() => {
    setRelated([{ new: true, relatedIssueId: '', relationType: '' }, ...related]);
    setShowAddRelated(true);
  }, [related]);

  return (
    <>
      <Grid container alignItems={'center'}>
        <Grid item>
          <Typography variant="h3" color={'#082253'} fontWeight={600}>
            {intl.formatMessage({ id: 'issue_page_related_title' })}
          </Typography>
        </Grid>
        <Grid item>
          {permissions.canManageRelations && (
            <IconButton onClick={handleShowAddRelated} sx={{ padding: '6px 4px' }} disabled={showAddRelated}>
              <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Grid container direction={'column'} sx={{ position: 'relative' }}>
        {error && (
          <Box sx={{ position: 'absolute', top: '-10px', left: 0 }}>
            <Typography variant="h3" color={'#E03737'}>
              {intl.formatMessage({ id: 'issue_related_error' })}
            </Typography>
          </Box>
        )}

        {related.length > 0 &&
          related.map((item, index) => {
            if (item.new || item.edit) {
              return (
                <Grid key={index} container columnSpacing={4} className={classes.relatedRow} marginTop={'10px'}>
                  <Grid item>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel className={classes.selectLabel} id="select-label">
                        {intl.formatMessage({ id: 'choose' })}
                      </InputLabel>
                      <Select
                        labelId="select-label"
                        onChange={event => handleRelationSelect(event, index)}
                        variant="outlined"
                        sx={{ width: '180px' }}
                        value={related[index].relationType}
                        className={classes.rowSelect}
                        MenuProps={{
                          classes: { paper: classes.paperDropdown },
                          anchorOrigin: {
                            vertical: 'center',
                            horizontal: 'center',
                          },
                        }}
                        IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                      >
                        {relationsSelectValues.map((item, index) => (
                          <MenuItem key={index} value={item.valueId}>
                            {intl.formatMessage({
                              id: `issue_page_${item.valueName.replaceAll(' ', '_').toLowerCase()}`,
                            })}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" color={'#41424E'} marginRight={'5px'}>
                      {`${intl.formatMessage({ id: 'issue_page_related_task_label' })} #`}
                    </Typography>
                    <Autocomplete
                      noOptionsText={intl.formatMessage({ id: 'not_found' })}
                      loadingText={intl.formatMessage({ id: 'search_options' })}
                      filterOptions={x => x}
                      onChange={(event, newValue) => handleRelatedIssueSelect(newValue, index)}
                      className={classes.filterSelect}
                      disablePortal
                      options={relatedIssueOptions}
                      disableClearable
                      sx={{ width: '156px' }}
                      loading={relatedIssueLoading}
                      onBlur={() => setRelatedIssueOptions([])}
                      renderOption={(props, option) => {
                        return (
                          <MenuItem {...props} key={option.valueId} value={option.valueId}>
                            {option.valueName}
                          </MenuItem>
                        );
                      }}
                      getOptionLabel={option => option.valueName || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                      renderInput={params => (
                        <TextField
                          {...params}
                          onChange={ev => handleRelatedIssueChange(ev.target.value)}
                          placeholder={intl.formatMessage({ id: 'choose' })}
                        />
                      )}
                      popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                      value={item.relatedIssueId}
                    />
                  </Grid>
                  {item.canDelay && (
                    <Grid item>
                      <Typography variant="h5" color={'#41424E'} marginRight={'5px'}>
                        {`${intl.formatMessage({ id: 'issue_page_related_task_delay' })}:`}
                      </Typography>
                      <TextField
                        onChange={event => handleNumberField(event, 'delay', index)}
                        placeholder={intl.formatMessage({ id: 'enter' })}
                        fullWidth
                        variant="outlined"
                        required
                        value={related[index].delay ? related[index].delay : ''}
                        InputLabelProps={{ shrink: false }}
                        className={'formTextField labelLeft'}
                      />
                      <Typography variant="h5" color={'#41424E'} marginLeft={'5px'}>
                        {intl.formatMessage({ id: 'issue_page_related_task_days' })}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <IconButton
                          onClick={() => handleSaveIssueRelated(index)}
                          sx={{ padding: '8px', height: '100%' }}
                        >
                          <SubmitIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => handleDeleteIssueRelated(index)}
                          sx={{ padding: '8px', height: '100%' }}
                          className={issueCommonStyles.hoveredIcon}
                        >
                          <DeleteIconBig />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            } else {
              return (
                <Grid key={index} container alignItems={'center'} marginTop={'10px'} lineHeight={'20px'}>
                  <Grid item>
                    <Typography variant="h4" color={'#212346'}>
                      {`${intl.formatMessage({
                        id: `issue_page_${item.relationName.replaceAll(' ', '_').toLowerCase()}`,
                      })} `}
                      {item.delay && `${item.delay} ${intl.formatMessage({ id: `issue_page_related_task_days` })} `}
                      <Link
                        to={`${window.location.origin}/issues/${item.relatedIssueId}`}
                        target={'_blank'}
                        className={issueCommonStyles.rowLink}
                      >
                        {item.relatedIssue}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                      sx={{ padding: '0 8px' }}
                      className={issueCommonStyles.hoveredIcon}
                      onClick={() => handleDeleteIssueRelated(index)}
                    >
                      <DeleteIconBig />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            }
          })}
      </Grid>
    </>
  );
};

export default injectIntl(IssueRelated);
