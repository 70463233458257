import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  Header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2000,
    backgroundColor: theme.palette.text.primary,
    padding: '8px 20px',
    [theme.breakpoints.maxWidth('sm')]: {
      padding: theme.spacing(7.5, 1.5, 2.5, 1.5),
      height: 80,
    },
  },
  headerLogo: {
    display: 'flex',
    width: theme.spacing(22),
    height: theme.spacing(10),
  },
  avatarBlock: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  userSettingsLink: {
    '&.MuiPopover-root': {
      zIndex: 2000,
    },
    '& .MuiPopover-paper': {
      padding: theme.spacing(3, 4),
      backgroundColor: 'white',
      borderRadius: theme.spacing(4),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(-5),
    },
  },
  logoutButton: {
    cursor: 'pointer',
    color: '#7174AC',
    display: 'block',
    //marginTop: theme.spacing(2),
  },
  headerContainer: {
    // maxWidth: 1280,
    padding: '0 10px 0 25px',
    marginLeft: theme.spacing(8.5),
    [theme.breakpoints.minWidth('xxl')]: {
      // maxWidth: '95%',
    },
    [theme.breakpoints.maxWidth('xxl')]: {
      // maxWidth: 1280,
    },
    [theme.breakpoints.maxWidth(1330)]: {
      // maxWidth: 944,
    },
    [theme.breakpoints.maxWidth('lg')]: {
      // maxWidth: 717,
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.maxWidth('md')]: {
      padding: 0,
    },
    [theme.breakpoints.maxWidth('sm')]: {
      maxWidth: 320,
      marginLeft: 0,
    },
  },
}));

export default useStyles;
