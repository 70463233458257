import { makeStyles } from '@mui/styles';

const ImportDataStyles = makeStyles(theme => ({
  filterSelect: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid transparent',
        backgroundColor: '#FFFFFF',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
        border: 'none',
      },
      '& .MuiAutocomplete-endAdornment': {
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
          position: 'static',
        },
      },
      '& .MuiAutocomplete-input': {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#7174AC',
        padding: '0',
      },
    },
    '&.MuiAutocomplete-hasClearIcon': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderColor: '#3448FF',
        '&:hover': {
          borderColor: '#3448FF',
        },
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      top: '-5px',
      zIndex: 99,
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: '180px!important',
      width: 'auto!important',
      maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        borderTop: '1px solid #E4E4EF',
        boxShadow: '0px 5px 10px -8px #A0A1AA66',
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: ' nowrap',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
}));

export default ImportDataStyles;
